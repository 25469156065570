<!-- eslint-disable vue/no-unused-components -->
<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>问诊医生：</label>
        <el-input
            size="small"
            placeholder="问诊医生"
            prefix-icon="el-icon-search"
            v-model="searchParams.doctors_name"
            clearable
            @change="getList('restPage')"
        />
      </div>
      <div class="search-container-fn-input">
        <label>患者信息：</label>
        <el-input
            size="small"
            placeholder="请输入患者姓名或手机号"
            prefix-icon="el-icon-search"
            v-model="searchParams.pat_keywords"
            clearable
            @change="getList('restPage')"
        />
      </div>
      <div class="search-container-fn-input">
        <label>创建时间：</label>
        <el-date-picker
            v-model="searchParams.created_at"
            :default-time="['00:00:00', '23:59:59']"
            type="daterange"
            size="mini"
            value-format="yyyy-MM-dd HH:mm:ss "
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="
                  getList('restPage')
                "
        >
        </el-date-picker>
      </div>
      <el-button
          size="mini"
          type="primary"
          style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
          @click="getList('restPage')"
      >
        <span>查找</span>
      </el-button>
      <div
          style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
              @click="handleDelete"
              v-if="multipleSelectionPres.length == 1"
          >删除
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('export') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit-outline"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
              @click="handleEmr"
          >书写病历
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('export') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
              @click="handleExport"
          >导出
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
              @click="handleDelete"
              v-if="multipleSelectionPres.length > 1"
          >批量删除
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit-outline"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
              @click="handleEditTableHead"
          >编辑表头
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
              @click="handleResetSearch"
          >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        height="725"
        @selection-change="handleSelectionChange"
        style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
            :prop="column.column_prop"
            :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
                style="font-size: 12px; color: #2379fb"
                @click.prevent="
                activeName = 'first';
                getePrescription(scope.row);
                getDetail(scope.row);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
            :prop="column.column_prop"
            :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
            show-overflow-tooltip
        >
        
          <template slot-scope="scope">
            <div v-if="column.column_label == '电子病历'">
              <div
                  @click="under(scope.row.emr_pic, 'pic' + scope.$index)"
                  v-if="scope.row.emr_pic.length != 0"
              >
                <a style="color:#2362FB" @click="img">查看图片</a>
                <el-image
                    :ref="'pic' + scope.$index"
                    style="width: 0; height: 0;"
                    :z-index="zIndex"
                    :src="
                    scope.row.emr_pic instanceof Array
                      ? scope.row.emr_pic[0]
                      : ''
                  "
                    :preview-src-list="
                    scope.row.emr_pic instanceof Array ? scope.row.emr_pic : []
                  "
                >
                </el-image>
              </div>
              <div v-else>
                暂无
              </div>
            </div>
            <div v-if="column.column_label == '处方笺'">
              <div
                  @click="under1(scope.row.pres_pic, 'pic1' + scope.$index)"
                  v-if="scope.row.pres_pic.length != 0"
              >
                <a style="color:#2362FB" @click="img1">查看图片</a>
                <el-image
                    :ref="'pic1' + scope.$index"
                    style="width: 0; height: 0;"
                    :z-index="zIndex1"
                    :src="
                    scope.row.pres_pic instanceof Array
                      ? scope.row.pres_pic[0]
                      : ''
                  "
                    :preview-src-list="
                    scope.row.pres_pic instanceof Array
                      ? scope.row.pres_pic
                      : []
                  "
                >
                </el-image>
              </div>
              <div v-else>
                暂无
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'text'&&column.visible === true"
          show-overflow-tooltip
        >

        </el-table-column> -->
        <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_prop == 'type'">
              {{ scope.row.type == 1 ? "图文问诊" : "视频问诊" }}
            </a>
            <a v-else-if="column.column_prop == 'supervise_status'">
              {{ scope.row.supervise_status | formatSuperviseStatus }}</a
            >
            <a v-else-if="column.column_prop == 'from_source'">
              {{ from_sources[scope.row.from_source] }}</a
            >
            <div v-else-if="column.column_prop == 'video_file'">
              <div v-if="scope.row.video_file.length == 0">
                暂无
              </div>
              <div v-else>
                <a :href="scope.row.video_file[0]" style="color: blue"
                >患者视频</a
                >
                <br/>
                <a :href="scope.row.video_file[1]" style="color: blue"
                >医生视频</a
                >
              </div>
            </div>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                    column.column_label_user_definition
                        ? column.column_label_user_definition
                        : column.column_label
                  }}</span>
                <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                  v-if="'type' == column.column_prop"
                  style="width: 200px"
                  @change="handleSearch(column.column_prop, searchParams.type)"
                  v-model="searchParams.type"
                  clearable
                  placeholder="请选择"
                  size="small"
              >
                <template v-for="item in superviseType">
                  <el-option
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-date-picker
                  v-if="'created_at' == column.column_prop"
                  v-model="searchParams.created_at"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH:mm:ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="
                  handleSearch(column.column_prop, searchParams.created_at)
                "
              >
              </el-date-picker>
              <el-select
                  v-if="'order_status' == column.column_prop"
                  style="width: 200px"
                  @change="
                  handleSearch(column.column_prop, searchParams.order_status)
                "
                  v-model="searchParams.order_status"
                  clearable
                  placeholder="请选择"
                  size="small"
              >
                <el-option
                    v-for="item in orderStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                  v-if="isAdmin == 1 && 'agent_name' == column.column_prop"
                  style="width: 200px"
                  @change="
                  handleSearch(column.column_prop, searchParams.agent_code)
                "
                  v-model="searchParams.agent_code"
                  clearable
                  placeholder="请选择"
                  size="small"
              >
                <el-option
                    v-for="item in optionsName"
                    :key="item.agent_code"
                    :label="item.agent_name"
                    :value="item.agent_code"
                >
                </el-option>
              </el-select>
              <el-select
                  v-if="'supervise_status' == column.column_prop"
                  style="width: 200px"
                  @change="
                  handleSearch(
                    column.column_prop,
                    searchParams.supervise_status
                  )
                "
                  v-model="searchParams.supervise_status"
                  clearable
                  placeholder="请选择"
                  size="small"
              >
                <el-option
                    v-for="item in superviseStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                  v-if="'order_sn' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.order_sn"
                  clearable
                  @change="
                  handleSearch(column.column_prop, searchParams.order_sn)
                "
              >
              </el-input>
              <el-input
                  v-if="'doctors_name' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.doctors_name"
                  clearable
                  @change="
                  handleSearch(column.column_prop, searchParams.doctors_name)
                "
              >
              </el-input>
              <el-input
                  v-if="'pat_name' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.pat_name"
                  clearable
                  @change="
                  handleSearch(column.column_prop, searchParams.pat_name)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
                column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
              }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
        :isActiveEditTableHead.sync="isActiveEditTableHead"
        @_getAdminFieldIndex="_getAdminFieldIndex"
        v-if="isActiveEditTableHead"
        @getList="getList"
        :table_type="table_type"
    >
    </editTableHead>
    <el-drawer
        v-loading="listLoading"
        :before-close="handleCloseDrawer"
        @closeDrawer="handleCloseDrawer"
        title=""
        size="76%"
        :visible.sync="drawerVisible"
        :with-header="false"
    >
      <therapyDetail
          @closeDrawer="handleCloseDrawer"
          :info="info"
          :id="pres_id"
          v-if="drawerVisible"
          :diag_id="diag_id"
      >
      </therapyDetail>
    </el-drawer>

    <!--处方详情查看-->
		<el-drawer
			title=""
			:visible.sync="emrVisible"
			direction="rtl"
			v-loading="listLoading"
			:before-close="handleCloseEmrDrawer"
			@closeDrawer="handleCloseEmrDrawer"
			size="70%"
			:with-header="false"
		>
			<emr
				@closeDrawer="handleCloseEmrDrawer"
				@getList="handleSaveEmr"
				:info="info"
				v-if="emrVisible"/>
		</el-drawer>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import therapyDetail from "./components/therapyDetail";
import therapyTableHead from "./tableHead/therapyTableHead";//单独表头(已弃用,目前使用公共组件)
import {
  getTherapy,
  getTherapyDetail,
  delTherapyById,
  exportPres
} from "@/api/informationSearch/index.js";
import {getAdminFieldIndex} from "@/api/drugs2.0";//获取表头,已弃用
import {ePrescription} from "@/api/informationSearch/index_2.0.js";
import {getCommonMerchants} from "@/api/chronicDisease/customerRelations/customerRelations";
import {mapState} from "vuex";
import {getAdminCommonMerchants} from "@/api/finance/index.js";
import editTableHead from "@/components/editTableHead/editTableHead";
import emr from "./components/emrDiglog.vue"
export default {
  name: "therapy",
  components: {
    Pagination,
    emr,
    // eslint-disable-next-line vue/no-unused-components
    therapyDetail,
    editTableHead,
  },
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
      isAdmin: (state) => state.user.is_admin,
    }),
  },
  async created() {
    this.is_admin = localStorage.getItem("is_admin");
    await this._getAdminFieldIndex();
    this.getList();
    // const res = await getTherapy();
    // console.log(res);
    const data = await getCommonMerchants();
    this.agentList = data.data;
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
    this._getAdminCommonMerchants();
  },
  data() {
    return {
      table_type: "therapy",
      optionsName: [], //机构
      flag: false,
      operateList: [],
      is_admin: "",
      agentList: [],
      emrVisible:false,
      isActiveEditTableHead: false,
      isActiveSelectTable: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      photo_type: [
        {
          value: 1,
          label: "有",
        },
        {
          value: 2,
          label: "无",
        },
      ],
      searchTableHead: [
        //控制搜索
        {
          name: "order_sn",
          isSearch: false,
        },
        {
          name: "doctors_name",
          isSearch: false,
        },
        {
          name: "agent_name",
          isSearch: false,
        },
        // {
        //   name: "emr_pic",
        //   isSearch: false,
        // },
        // {
        //   name: "pres_pic",
        //   isSearch: false,
        // },
        {
          name: "type",
          isSearch: false,
        },
        {
          name: "supervise_status",
          isSearch: false,
        },
        {
          name: "pat_name",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
        {
          name: "order_status",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      superviseStatusList: [
        {
          value: "1",
          label: "未上传",
        },
        {
          value: "2",
          label: "待上传",
        },
        {
          value: "3",
          label: "已上传",
        },
      ],
      info: {},
      currTherapy: {},
      dataInfo: {},
      pres_id: "", //处方ID
      prescriptionDetail: {}, //电子处方
      chatList: [], //问诊记录列表
      from_sources: ["患者场景", "医生场景", "慢病场景"],
      examine_status: ["待审核", "一审通过", "二审通过", "拒绝"],
      superviseType: [
        {
          value: "1",
          label: "图文",
        },
        {
          value: "2",
          label: "视频",
        },
      ],
      orderStatusList: [
        {value: 1, label: "待接诊"},
        {value: 2, label: "接诊中"},
        {value: 3, label: "已取消"},
        {value: 4, label: "待评价"},
        {value: 5, label: "已完成"},
        {value: 6, label: "退款中"},
        {value: 7, label: "已退款"},
      ],
      therapyStatus: ["已取消", "待接诊", "接诊中", "已结束"],
      superviseStatus: ["未上传", "待上传", "已上传"],
      tableData: [],
      dataDrawerTab1: {},
      dataDrawerTab2: {},
      searchParams: {
        orderTime: [],
      },
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      drawerVisible: false,
      // 图片遮罩相关
      seen: false,
      current: null,
      isPicIdx: null,
      seen2: false,
      current2: 0,
      isPicIdx2: "",
      // 默认图片层级
      zIndex: 2000,
      zIndex1: 2000,
      flag1: false,
      tableHead: [],
    };
  },
  filters: {
    formatOrderStatus(v) {
      // 已取消，待接诊，接诊中，已结束
      if (v === 1) {
        return "待接诊";
      } else if (v === 2) {
        return "接诊中";
      } else if (v === 3) {
        return "已取消";
      } else if (v === 4) {
        return "待评价";
      } else {
        return "已完成";
      }
    },
    formatSuperviseStatus(v) {
      // 未上传，待上传，已上传。
      if (v === 1) {
        return "未上传";
      } else if (v === 2) {
        return "待上传";
      } else {
        return "已上传";
      }
    },

    //格式化来源
    formateFromSource(v) {
      if (v === 1) {
        return "患者场景";
      } else if (v === 2) {
        return "医生场景";
      } else if (v === 3 || v === 5) {
        return "慢病场景";
      } else {
        return "-";
      }
    },
  },

  methods: {
    handleSaveEmr(){
      this.getList()
      this.emrVisible = false
    },
    //机构选择
    _getAdminCommonMerchants() {
      getAdminCommonMerchants().then((res) => {
        if (res.code == 200) {
          let infoList = res.data.map((item) => {
            return {
              agent_code: item.agent_code,
              agent_name: item.merchant_name,
            };
          });
          this.optionsName = infoList;
          console.log(this.optionsName);
        }
      });
    },
    img() {
      this.flag = true;
    },
    img1() {
      this.flag1 = true;
    },
    under1(pics, refPic = "pic1") {
      console.log(pics, refPic, "处方笺");
      if (pics.length > 0) {
        console.log(123, this.$refs[refPic][0], 596458645);
        this.zIndex1 = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    // 不同层级指定不同refs
    under(pics, refPic = "pic") {
      console.log(pics, refPic, "电子病历");
      if (pics.length > 0) {
        console.log(123, this.$refs[refPic][0], 596458645);
        this.zIndex = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    handleEmr(){
      let row = this.multipleSelectionPres;
      if(row.length > 1 || row.length == 0){
        this.$message({
          message: "请选择一条数据",
          type: "warning",
        });
        return 
      }
      this.info = this.multipleSelectionPres[0]
      this.emrVisible = true
    },
    getePrescription(row) {
      if (!row.pres_id) {
        return;
      } else {
        let params = {
          pres_id: row.pres_id,
        };
        ePrescription(params).then((res) => {
          if (res.code == 200) {
            this.prescriptionDetail = res.data;
            this.prescriptionDetail.w_drugs.forEach((item) => {
              item.pres_not ? (item.pres_not = "是") : (item.pres_not = "否");
            });
            console.log(this.prescriptionDetail, 222);
          }
        });
      }
    },
    handleCustomerDetail(row, e) {
      console.log(row, e);
      if (e == "doctor_name") {
        this.getInfo(row.doctors_id);
      } else if (e == "professionalName") {
        this.handleSetInquiryPrice(row);
      } else if (e == "show_sort") {
        this.handleSort(row);
      } else if (e == "is_bind") {
        this.handleAssistant(row);
      }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "therapy",
      //   };
      //   const res = await getAdminFieldIndex(params);
      this.tableHead = this.table_options.therapy;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getTherapy(params)
          .then((response) => {
            let data = response;
            if (data.code == 200) {
              let _data = data.data;
              this.total = _data.total;
              this.listQuery.limit = Number(_data.per_page);
              this.tableData = _data.list;
            }
            this.listLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.listLoading = false;
          });
    },
    getDetail(row) {
      this.drawerVisible = !this.drawerVisible;
      this.diag_id = row.diag_id;
      this.pres_id = row.pres_id;
      this.listLoading = true;
      let params = {
        diag_id: row.diag_id,
      };
      getTherapyDetail(params)
          .then((response) => {
            let data = response;
            console.log(data);
            if (data.code == 200) {
              this.info = data.data;
            }
            this.listLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.listLoading = false;
          });
    },
    handleDelete() {
      let row = this.multipleSelectionPres;
      let arr = [];
      row.forEach((item) => {
        //a将item.diag_id push进
        arr.push(item.diag_id);
      });
      console.log(arr, "4823823");
      this.$confirm("确认删除问诊信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            delTherapyById({diag_id: arr}).then((res) => {
              if (res.code == 200) {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    handleCloseEmrDrawer(){
      this.emrVisible = false;
    },
    handleCloseDrawer() {
      this.dataDrawer = {};
      this.tableDataDrawer = [];
      this.drawerVisible = false;
    },
    handleExport() {
      this.listLoading = true;
      let params = Object.assign({}, this.searchParams);
      params.field_type = "therapy";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      exportPres(params)
          .then((response) => {
            let data = response;
            loading.close();
            if (data) {
              const xlsx = "application/vnd.ms-excel";
              const blob = new Blob([data], {
                type: xlsx,
              });
              const a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
              a.download = "格格健康平台问诊信息表.xlsx";
              a.href = window.URL.createObjectURL(blob);
              a.click();
              a.remove();
              this.isActiveSelectTable = false;
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
            this.listLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    //关闭弹窗,刷新列表
    closeDrawer(e) {
      console.log(e);
      this.drawerVisible = e;
      this.info = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.isSearch {
  color: #f56c6c;
}

.search-icon {
  color: #f56c6c;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
::v-deep .el-drawer {
  bottom: 0 !important;
}

::v-deep .el-drawer__header {
  padding: 0;
  margin: 0;
}

::v-deep .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}

::v-deep .el-table .warning-row {
  color: red;
}
</style>
