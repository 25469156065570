<template>
    <div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading" style="overflow: visible ;padding: 0">
        <el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button>
        <div style="padding:30px;box-sizing: border-box;">
            <el-tabs v-model="activeName"  style="font-size: 14px;box-sizing: border-box"
						 class="tab-container">
			    <el-tab-pane label="书写病历" name="first" style="font-size: 14px; font-weight: 400; background-color: #f7f7f7;">
                    <div>
                        <el-row :gutter="10" style="margin: 20px 10px 20px 10px;background-color: #f7f7f7;">
                            <el-col :span="24" style="background-color: white;min-height: 760px;padding: 20px 10px 20px 20px;">
                                <div class="grid-content bg-purple" style="display: flex;  align-items: center;">
                                    <div class="required"></div>
                                    <span>患者信息</span>
                                </div>
                                <div class="grid-content bg-purple">
                                    <el-row>
                                        <el-col :span="2">
                                            <div class="grid-content bg-purple relation-container" style="color: #777">
												姓名
											</div>
                                        </el-col>
                                        <el-col :span="6">
											<div class="grid-content bg-purple relation-container number-align">
												{{ info.pat_name }}
											</div>
										</el-col>
                                        <el-col :span="2">
											<div class="grid-content bg-purple relation-container" style="color: #777">
												性别
											</div>
										</el-col>
                                        <el-col :span="6">
											<div class="grid-content bg-purple relation-container number-align">
												{{ info.pat_gender }}
											</div>
										</el-col>
                                        <el-col :span="2">
											<div class="grid-content bg-purple relation-container" style="color: #777">
												年龄
											</div>
										</el-col>
                                        <el-col :span="6">
											<div class="grid-content bg-purple relation-container number-align">
												{{ info.pat_age }}
											</div>
										</el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="2">
											<div class="grid-content bg-purple relation-container" style="color: #777">
												患病时长
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container number-align">
												{{info.ill_duration}}
											</div>
										</el-col>
										<el-col :span="2">
											<div class="grid-content bg-purple relation-container" style="color: #777">
                                                手机号
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container number-align">
												{{info.pat_phone}}
											</div>
										</el-col>
										<el-col :span="2">
											<div class="grid-content bg-purple relation-container" style="color: #777">
                                                病历图片
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container"
														 style="padding: 0">
                                                <!--图片遮罩效果-->
                                                <div @mouseenter="enter('drawerPic1')" @mouseleave="leave()"
                                                            @click="under(info.photo, 'drawerPic1')"
                                                            style="position: relative"
                                                            v-if="info.photo && info.photo.length > 0">
                                                    <transition name="fade">
                                                        <div v-if=" 'drawerPic1' === isPicIdx"
                                                                    style="position: absolute;background-color: rgba(101, 101, 101, .3); width: 80px; height: 80px; z-index: 3999">
                                                        </div>
                                                    </transition>
                                                    <i v-show="'drawerPic1' === isPicIdx " class="el-icon-zoom-in"
                                                            style="position: absolute;top: 50%;left: 50%; transform: translate(-50%, -50%); font-size:26px; color: #fff; width: 30px; height: 30px; z-index: 4000"></i>
                                                    <el-image ref="drawerPic1" style="width: 80px; height: 80px;"
                                                                        :z-index=zIndex
                                                                        :src="info.photo instanceof Array ? info.photo[0] : '' "
                                                                        :preview-src-list="info.photo instanceof Array ? info.photo : [] ">
                                                    </el-image>
                                                </div>
                                                <div v-else>
                                                    暂无
                                                </div>
                                            </div>
										</el-col>
									</el-row>
                                </div>

                                <div class="grid-content bg-purple" style="display: flex;  align-items: center;">
                                    <div class="required"></div>
                                    <span>患者病历</span>
                                </div>
                                <div class="grid-content bg-purple">
                                    <el-form
                                        ref="ruleForm"
                                        :model="form"
                                        label-position="left"
                                        label-width="110px"
                                        :rules="rules"
                                        class="demo-ruleForm"
                                    >
                                        <el-row>
                                            <el-col :span="24">
                                                <el-form-item label="基本诊断" prop="disease_id" required>
                                                    <el-select
                                                        :multiple-limit="4"
                                                        style="width: 100%;"
                                    
                                                        v-model="form.disease_id"
                                                        multiple
                                                        filterable
                                                        remote
                                                        reserve-keyword
                                                        placeholder="请输入关键词"
                                                        :remote-method="remoteMethod"
                                                        :loading="loading">
                                                        <el-option
                                                            v-for="item in diseaseOpts"
                                                            :key="item.id"
                                                            :label="item.disease_name"
                                                            :value="item.id">
                                                                <span style="margin-right: 10px;">{{ item.disease_name }}({{ item.disease_code }})</span>
                                                                <el-tag type="primary" size="mini">{{ item.type == 1 ? '西医诊断' : '中医诊断' }}</el-tag>
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="24">
                                                <el-form-item label="主诉" prop="desc" required>
                                                    <el-input v-model="form.desc" placeholder="请输入主诉"></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="24">
                                                <el-form-item label="现病史" prop="now_past" required>
                                                    <el-input v-model="form.now_past" placeholder="请输入现病史"></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="24">
                                                <el-form-item label="既往史">
                                                    <el-select
                                                        style="width: 100%;"
                                                        v-model="form.past_medical_history"
                                                        :multiple-limit="pastLimit"
                                                        multiple
                                                        filterable
                                                        allow-create
                                                        default-first-option
                                                        @change="handleSelectPast"
                                                        placeholder="请选择既往史">
                                                        <el-option
                                                            
                                                            v-for="item in pastOpts"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="24">
                                                <el-form-item label="治疗意见" prop="advices" required>
                                                    <el-input type="textarea" :row="3" v-model="form.advices" placeholder="请输入治疗意见"></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="24">
                                                <el-form-item label="医嘱">
                                                    <el-input type="textarea" :row="3" v-model="form.doctor_advices" placeholder="请输入医嘱"></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                    <span slot="footer" class="dialog-footer">
                                        <el-button type="success" size="small" @click="saveForm('ruleForm')"
                                        >提交病历</el-button
                                        >
                                    </span>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import { methods } from 'vue-echarts';
import { getDiseases,saveEmrApi } from "@/api/informationSearch/index_2.0.js"

export default {
    name:"emr",
    props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: String,
      default: 'add'
    },
    doctorType: {
      type: [Number, String],
      default: 1
    },
    status: {
      type: [Number, String],
      default: 1
    },
    info: {
      type: Object
    },
    diag_id: {
      type: [String, Number]
    },


  },
    data:()=>{
        return {
            pastLimit:1,
            diseaseList:[],
            diseaseOpts:[],
            activeName: 'first',
            fullscreenLoading: false,
            // 图片遮罩相关
            seen: false,
            current: null,
            isPicIdx: null,
            zIndex: 2000,
            form:{
                disease_id:[],
                desc:"",
                now_past:"无",
                past_medical_history:["无"],
                advices:"服药治疗"
            },
            rules: {
                disease_id:[{required:true,message:"请选择诊断",trigger:"change"}],
                desc:[{required:true,message:"请填写主诉",trigger:"blur"}],
                now_past:[{required:true,message:"请填写现病史",trigger:"blur"}],
                advices:[{required:true,message:"请填写治疗意见",trigger:"blur"}],
            },
            pastOpts:[
                {label:"无",value:"无"},
                {label:"月经失调",value:"月经失调"},
                {label:"艾滋病",value:"艾滋病"},
                {label:"肾衰竭",value:"肾衰竭"},
                {label:"肿瘤",value:"肿瘤"},
                {label:"乳腺疾病",value:"乳腺疾病"},
                {label:"消化系统疾病",value:"消化系统疾病"},
                {label:"呼吸系统疾病",value:"呼吸系统疾病"},
                {label:"心脏病",value:"心脏病"},
                {label:"甲状腺疾病",value:"甲状腺疾病"},
                {label:"心血脑管病",value:"心血脑管病"},
                {label:"脂肪肝",value:"脂肪肝"},
                {label:"高血脂",value:"高血脂"},
                {label:"肥胖症",value:"肥胖症"},
                {label:"高血压",value:"高血压"},
                {label:"糖尿病",value:"糖尿病"},
                {label:"其他",value:"其他"},
            ]
        }
    },
    async mounted () {
        this.form.desc = this.info.desc
        this.form.now_past = "无"
        await this._getDisease()
    },
    methods:{
        async _getDisease(){
            const res = await getDiseases()
            if(res.code == 200){
                this.diseaseList = res.data
                this.diseaseOpts = Object.assign({},res.data)
            }
        },
        async saveForm(){
            this.$refs["ruleForm"].validate(async (valid) => {
                if (valid) {
                    this.form.diag_id = this.info.diag_id
                    const res = await saveEmrApi(this.form)
                    if (res.code == 200){
                        this.$emit("getList")
                    }else {
                        this.$message.error(res.msg)
                    }

                }
            });
        },
        handleSelectPast(e){
            if(e.length == 0){
                this.pastLimit = 3
            }else {
                const past = e[e.length-1]
                if(past == "无"){
                    this.form.past_medical_history = ["无"]
                    this.pastLimit = 1
                }
            }
            

            console.log(123,this.form)
        },
        async remoteMethod(e){
            const res = await getDiseases({keyword:e})
            if(res.code == 200){
                this.diseaseOpts = res.data
            }
        },
        closeDrawer(){
            console.log(123123)
            this.$emit('closeDrawer')
        },
        enter (index = null) {
            this.seen = true;
            this.current = index;
            this.isPicIdx = index
        },
        leave () {
            this.seen = false;
            this.current = null;
            this.isPicIdx = null;
        },
        under (pics, refPic = 'pic') {
        if (pics.length > 0) {
            this.zIndex = 9999
            this.$refs[refPic].showViewer = true
        }
        },
    }
}
</script>
<style scoped lang="scss">
.gg-container {
		box-sizing: border-box;
		margin: 0;
	}
	
	::v-deep.tabs-container {
		.el-tabs__header {
			padding: 0;
		}
	}
	
	::v-deep .el-tab-pane {
		min-height: 800px;
		overflow: visible;
	}
    .margin-t-10 {
		margin-top: 30px;
	}
	
	/* 蓝色方块 */
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	
	/*说明文字样式*/
	.title-pre-l {
		width: 120px;
		color: #777
	}
	
	/*鼠标滑动,详情颜色改变*/
	.is-hover {
		color: #333;
	}
	
	.is-hover:hover {
		color: #4ebafa
	}
	
	/*问诊记录*/
	.msg-img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		overflow: hidden;
	}
	
	.information {
		.msg-left {
			display: flex;
			margin-bottom: 10px;
			
			.msg-text {
				font-size: 12px;
				margin-left: 10px;
				
				.msg-text-b {
					margin-top: 5px;
					padding: 10px;
					background-color: #f7f7f7;
					border-radius: 8px;
					max-width: 350px;
					word-break: break-word
				}
			}
			
		}
		
		.msg-right {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 10px;
			
			.msg-text {
				font-size: 12px;
				margin-right: 10px;
				text-align: right;
				
				.msg-text-b {
					margin-top: 5px;
					padding: 10px;
					background-color: #f7f7f7;
					border-radius: 8px;
					max-width: 350px;
					word-break: break-word
				}
			}
		}
	}
	
	
	/*栅格文本居中*/
	.relation-container {
		display: flex;
		/*justify-content:center;!*主轴上居中*!*/
		align-items: center;
		/*侧轴上居中*/
	}
	
	.number-align {
		padding-top: 4px;
	}
	
	/*栅格边框*/
	.el-row {
		
		/*margin-bottom: 20px;*/
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.el-col {
		border-radius: 4px;
	}
	
	::v-deep .el-tab-pane {
		overflow: auto;
	}
	
	/*测试排版使用颜色*/
	/*.bg-purple-dark {*/
	/*	background: #99a9bf;*/
	/*}*/
	
	/*.bg-purple {*/
	/*	background: #d3dce6;*/
	/*}*/
	
	/*.bg-purple-light {*/
	/*	background: #e5e9f2;*/
	/*}*/
	
	.grid-content {
		border-radius: 4px;
		min-height: 30px;
	}
	
	
	.follow-up-container {
		margin-top: 20px;
		display: flex;
		align-items: center;
		
		.follow-up-select-wrapper {
			margin-left: 30px;
			margin-right: 20px;
			font-weight: bolder;
			color: #777;
		}
	}
	
	.follow-up-time {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: left;
		
		.title {
			width: 90px;
			font-weight: bolder;
			color: #777;
		}
		
		.content {
		
		}
	}
	
	.follow-up-content {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: left;
		
		.title {
			width: 90px;
			flex-shrink: 0;
			height: 100px;
			font-weight: bolder;
			color: #777;
		}
		
		.content {
			display: flex;
			justify-content: left;
			flex-direction: column;
			height: 100px;
			
			.up {
			
			
			}
			
			.down {
			
			}
		}
	}
</style>